<template>
  <j-abm
    name="account"
    :suppliers="suppliers"
    :features="{
      create: true,
      update: true,
      delete: false,
    }"
    @create="onCreate"
    @update="onUpdate">
    <template #form="{item}">
      <!-- Contact -->
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('contact') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
            ref="contact"
            :model="item.contact"
            :rules="rules">
          <j-input
            prop="name"
            v-model="item.contact.name"
            icon="apple" />
          <j-input
            prop="phone"
            v-model="item.contact.phone"
            :format="f.phone"
            icon="phone" />
          <j-input
            prop="email"
            v-model="item.contact.email"
            :format="f.lower"
            icon="mail" />
          <j-input
            prop="identification"
            v-model="item.contact.identification"
            icon="idcard" />
        </a-form-model>
      </div>
      <a-divider />
      <!-- Settings -->
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('settings') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
          ref="general"
          :model="item"
          :rules="rules">
          <j-enum-select
              prop="plan"
              v-model="item.plan" />
          <j-enum-select
              prop="size"
              v-model="item.size" />
          <j-field
            prop="cutoff">
            <a-slider
              v-model="item.cutoff"
              :marks="cutoffs(item.cutoff)"
              :min="0"
              :max="24" />
          </j-field>
          <j-field
            prop="extraMin">
            <a-input-number
              v-model="item.extra.min"
              :min="0"
              :max="12"
              :mark="5" />
          </j-field>
          <j-field
            prop="extraMax">
            <a-input-number
              v-model="item.extra.max"
              :min="0"
              :max="12" />
          </j-field>
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import { activation } from '@/utils/styles'
import JAbm from '@/views/shared/abm/update'
import JField from '@/views/shared/forms/field'
import JInput from '@/views/shared/forms/input'
import JEnumSelect from '@/views/shared/forms/enum-select'
export default {
  components: {
    JAbm,
    JInput,
    JField,
    JEnumSelect,
  },
  data: function () {
    return {
      f,
      acl,
      activation,
      rules: {
        name: [v.required],
        cutoff: [v.required],
        plan: [v.required],
        size: [v.required],
        phone: [v.required, v.phone],
        email: [v.required, v.email],
        identification: [v.required],
      },
    }
  },
  computed: {
    suppliers() {
      return {
        fetchOne: id => lms.account.fetchOne(id),
        payload: () => {
          return {
            country: 'AR',
            size: 'S',
            plan: 'BRONZE',
            cutoff: 12,
            contact: {},
            extra: {
              min: 0,
              max: 0,
            },
          }
        },
      }
    },
  },
  methods: {
    cutoffs(current) {
      const marks = {}
      const suffix = current > 12 ? 'PM' : 'AM'
      const number = current > 12 ? current - 12 : current
      marks[current] = `${number} ${suffix}`
      return marks
    },
    onCreate(account) {
      this.onSubmit(account, account => lms.account.create(account))
    },
    onUpdate(account) {
      this.onSubmit(account, account => lms.account.update(account))
    },
    onSubmit(account, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.general.validate(resolve)),
        new Promise((resolve, reject) => this.$refs.contact.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(account).then(_ => this.$router.push('/accounts'))
        }
      })
    },
  },
}
</script>
